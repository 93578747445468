import React, { useRef, useEffect } from "react"
import Img from "gatsby-image"
import { useSelector } from "react-redux"
import { initVimeoPlayerWithControl } from "../../utils"
import CTABtn from "../../Button/CTA"
import Explore from "../Home/Explore"
import Join from "../Home/Join"

export default function ArticleThree({ sanity }) {
  const isChinese = useSelector(state => state.lang.locale) === "tc"
  return (
    <div>
      <SectionIntro isChinese={isChinese} />
      <SectionTwo isChinese={isChinese} data={sanity} />
      <SectionThree isChinese={isChinese} data={sanity} />
      <SectionFour isChinese={isChinese} data={sanity} />
      <SectionFive isChinese={isChinese} data={sanity} />
      <SectionSix isChinese={isChinese} data={sanity} />
      <SectionSeven isChinese={isChinese} />
      <Explore home={sanity.sanityHomePage} />
      <Join home={sanity.sanityHomePage} />
      <CTABtn />
    </div>
  )
}

function SectionIntro() {
  const video = useRef(null)

  useEffect(() => {
    video.current &&
      initVimeoPlayerWithControl({
        selector: video.current,
        desktopVid: "https://vimeo.com/513717740/74e9adcf99",
        mobileVid: "https://vimeo.com/513766150/f460703934",
      })
  }, [])

  return (
    <section>
      <div ref={video}></div>
    </section>
  )
}

function SectionTwo({ isChinese }) {
  return (
    <section className="xxl:py-40 md:py-20 py-32">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="md:w-full md:px-12 w-10/12">
            <h2 className="quotes md:text-6xl bold-font md:mb-10 mb-20">
              90-Seconds with BMW Professional Vol. 6: BMW Service
            </h2>
            <p className="large-text">
              {isChinese
                ? "在第６集的 90-Second with BMW Professional，BMW Service 會和你探討一下定期保養課題，相信不少車主都有疑問究竟幾時應該返黎做保養。"
                : "BMW Service is going to talk about the importance of Regular Maintenance."}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionThree({ isChinese, data }) {
  return (
    <section className="xxl:py-40 md:py-20 bg-brand-light-grey py-32">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="md:w-full md:px-12 w-10/12">
            <div className="image-container xxl:mb-40 mb-32">
              <Img fluid={data.articleimg.childImageSharp.fluid} />
            </div>
            <div className="md:flex-col flex">
              <div className="md:w-full w-1/2 pr-20">
                <div className="xxl:text-5xl md:flex-col md:justify-center flex text-4xl">
                  <p className="bold-font md:w-full md:text-center md:mb-10 font-bold">
                    {isChinese
                      ? "BMW 智慧保養系統"
                      : "As a BMW owner, you may ask “When should I return for maintenance?"}
                  </p>
                </div>
              </div>
              <div className="md:w-full md:pl-0 w-1/2 pl-20">
                <div className="flex">
                  <hr className="md:w-full w-40 h-2 mb-32 bg-black" />
                </div>
                {isChinese ? (
                  <p className="light-font xxl:text-5xl text-4xl">
                    藉由車輛內建的晶片依據你的駕駛習慣與方式，監測消耗性零件與機油的耗損情形，度身打造專屬於你的保養建議，並通過儀錶板上的
                    CBS 指示燈，提示你預約維修保養。
                  </p>
                ) : (
                  <p className="light-font xxl:text-5xl text-4xl">
                    <b className="bold-font">
                      BMW Condition Based Service System
                    </b>{" "}
                    incorporates a built-in chip to monitor the wear-and-tear of
                    consumables and engine oil according to driving habits and
                    patterns and provide tailor-made maintenance advice for you.
                    The{" "}
                    <b className="bold-font">
                      CBS light on the instrument panel
                    </b>{" "}
                    in your vehicle prompts you to schedule a{" "}
                    <b className="bold-font">maintenance appointment</b>.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionFour({ isChinese, data }) {
  return (
    <section className="xxl:py-40 md:py-20 bg-brand-light-grey py-32">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="md:w-full md:px-12 w-10/12">
            <div className="md:flex-col xxl:mb-40 flex mb-32">
              <div className="md:w-full w-8/12">
                {isChinese ? (
                  <p className="large-text light-font mb-20">
                    當<b className="bold-font">黃色警告燈</b>
                    亮起，代表服務期限將到，可以預約維修保養。
                  </p>
                ) : (
                  <p className="large-text light-font mb-20">
                    If a <b className="bold-font">yellow warning light</b> is
                    on, it means that your BMW is due for service soon and you
                    may need to schedule an appointment.
                  </p>
                )}
                {isChinese ? (
                  <p className="large-text light-font">
                    當<b className="bold-font">紅色警告燈</b>
                    亮起，則表示服務期限已過，需要立即安排維修保養。
                  </p>
                ) : (
                  <p className="large-text light-font">
                    If the <b className="bold-font">red warning light</b> is on,
                    it means that the service deadline has expired and you need
                    to arrange a service booking as soon as possible.
                  </p>
                )}
              </div>
            </div>
            <div className="image-container md:hidden">
              {isChinese ? (
                <Img fluid={data.articleimg2.childImageSharp.fluid} />
              ) : (
                <Img fluid={data.articleimg2eng.childImageSharp.fluid} />
              )}
            </div>
            <div className="image-container md:block hidden -mx-12">
              {isChinese ? (
                <Img fluid={data.articleimg2mobile.childImageSharp.fluid} />
              ) : (
                <Img fluid={data.articleimg2engmobile.childImageSharp.fluid} />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionFive({ isChinese, data }) {
  return (
    <section className="md:py-20 py-40">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="md:w-full xxl:px-40 md:px-0 w-10/12 px-20">
            <div className="text-center">
              <div className="flex justify-center">
                <hr className="md:w-full md:hidden w-64 h-1 mt-32 mb-20 bg-black" />
              </div>
              <h3 className="quotes--sm md:text-4xl bold-font md:leading-normal md:mt-20 leading-relaxed text-center">
                {isChinese
                  ? "除了CBS指示燈，更可以在 iDrive系統的車輛資料瀏覽到一些車輛狀態，例如機油狀態和煞車零件狀態等等。"
                  : "Besides the CBS light, you can view the vehicle information via iDrive system to check vehicle condition such as engine oil levels, braking condition etc."}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionSix({ isChinese, data }) {
  return (
    <section className="md:pb-20 pb-40">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="md:w-full w-10/12 px-12">
            <div className="mt-40 text-center">
              {isChinese ? (
                <p className="large-text mb-20 leading-relaxed text-center">
                  作為BMW車主的你，我們建議每12個月或12,000公里<sup>*</sup>
                  ，進行原廠檢查及保養服務
                </p>
              ) : (
                <p className="large-text mb-20 leading-relaxed text-center">
                  As a BMW owner, we recommend you having a service booking for
                  a regular inspection and maintenance service every{" "}
                  <b className="bold-font">12 months </b>
                  or <b className="bold-font">12,000 km</b>
                  <sup>*</sup>.
                </p>
              )}
              <p className="large-text light-font leading-relaxed text-center">
                <sup>*</sup>
                {isChinese ? "以先到者為準" : "whichever comes first."}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionSeven({ isChinese }) {
  return (
    <section className="md:py-20 md:my-20 bg-brand-light-grey py-40 my-40">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="md:w-full w-10/12 px-12">
            <div className="md:flex-col flex justify-center">
              <div className="md:w-full w-8/12">
                <p className="large-text mb-20 leading-relaxed">
                  {isChinese ? (
                    <p className="large-text mb-20 leading-relaxed">
                      而 BMW
                      定期保養服務涵蓋67項全面原廠檢查及保養項目，包括原廠指定機油，油隔及原廠冷氣風隔更換服務讓你的BMW保持高水準的駕馭樂趣。
                      <br />
                      <br />
                      <a
                        href="http://bit.ly/3dplnbl"
                        target="_blank"
                        className="underline"
                      >
                        了解更多
                      </a>
                    </p>
                  ) : (
                    <p className="large-text mb-20 leading-relaxed">
                      <b className="bold-font">
                        BMW Regular Maintenance Service
                      </b>{" "}
                      covers 67 BMW inspection and maintenance items including
                      engine oil, oil filter and air conditioner filter
                      replacement service to sheer driving pleasure with your
                      BMW.
                      <br />
                      <br />
                      <a
                        href="http://bit.ly/3dplnbl"
                        target="_blank"
                        className="underline"
                      >
                        Learn More
                      </a>
                    </p>
                  )}
                </p>
                <div className="flex justify-center">
                  <hr className="md:w-full md:hidden w-40 h-1 bg-black" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
