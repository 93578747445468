import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Article from "../components/PageComponents/Article/BMWProfessional6"
import localize from "../components/utils/localize.js"

const IndexPage = ({ location, pageContext, data }) => (
  <Layout location={location} context={pageContext}>
    <SEO title="90-Seconds with BMW Professional Vol. 6: BMW Service" />
    <Article sanity={data} />
  </Layout>
)

export default localize(IndexPage)

export const pageQuery = graphql`
  query ArticleProfessionalVol6PageQuery {
    articleimg: file(name: { eq: "A0284144" }) {
      childImageSharp {
        fluid(maxWidth: 4000, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    articleimg2: file(name: { eq: "BMW_ServiceCHI_DESKTOP" }) {
      childImageSharp {
        fluid(maxWidth: 4000, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    articleimg2mobile: file(name: { eq: "BMW_ServiceCHI_MOBILE" }) {
      childImageSharp {
        fluid(maxWidth: 4000, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    articleimg2eng: file(name: { eq: "BMW_ServiceENG_DESKTOP" }) {
      childImageSharp {
        fluid(maxWidth: 4000, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    articleimg2engmobile: file(name: { eq: "BMW_ServiceENG_MOBILE" }) {
      childImageSharp {
        fluid(maxWidth: 4000, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    articleimg4: file(name: { eq: "Regular-inspection" }) {
      childImageSharp {
        fluid(maxWidth: 4000, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sanityHomePage {
      exploreSectionTitle {
        _type
        en
        tc
      }
      exploreSectionParagraph {
        _type
        en
        tc
      }
      formTitle {
        _type
        en
        tc
      }
      howToJoin {
        _type
        en
        tc
      }
      howToJoinStepOne {
        _type
        en
        tc
      }
      howToJoinStepThree {
        _type
        tc
        en
      }
      howToJoinStepTwo {
        _type
        en
        tc
      }
      joinSectionParagraph {
        _type
        en
        tc
      }
      joinSectionTitle {
        _type
        en
        tc
      }
      submit {
        _type
        en
        tc
      }
      adventureTitle {
        _type
        en
        tc
      }
      embrace {
        _type
        en
        tc
      }
      freedomTitle {
        _type
        en
        tc
      }
      independenceTitle {
        _type
        en
        tc
      }
      inspirationTitle {
        _type
        en
        tc
      }
      progressTitle {
        _type
        en
        tc
      }
      wisdomTitle {
        _type
        tc
        en
      }
    }
  }
`
